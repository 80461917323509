import {Usuario} from "../../model/usuario";
import {Conversa} from "./conversa";
import {Arquivo} from "../../model/arquivo";
import {Localizacao} from "./localizacao";

export class Mensagem {
	id: number;
	usuario: Usuario;
	texto: string;
	data: Date;
	lida: boolean;
	conversa: Conversa;
	tipo: string;
	duracao: number;
	arquivo: Arquivo;
	frameVideo: Arquivo;
	localizacao: Localizacao;

  urlArquivo: string;
}
